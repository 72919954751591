import { Component, HostListener, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Page } from 'src/app/data/models/config';
import { ConfigService } from 'src/app/data/services/config.service';
import { OutputService } from 'src/app/data/services/output.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { OnboardingApiService } from 'src/app/data/services/onboarding-api.service';
import { PrePopData } from 'src/app/data/models/prepopData';
import { PartialSignupData } from 'src/app/data/models/PartialSignupData';
import { LoadExternalToolsGuard } from "src/app/data/services/exter-tools/external-tools.guard";
import { CustomPreloadingStrategy } from 'src/app/data/services/custom.preloadStrategy';

const videoLink = 'https://player.vimeo.com/video/675070996?h=8d43cd2519';
const devicesBreakpoint = {
  sm: 480,
  md: 1200
}

type _PrepopAPIResponse = {
  result: PrePopData;
  success: boolean;
  errors: unknown;
}

type _PartialSignupAPIResponse = {
  partialSignupPayload: string;
  partialSignupLastPage: string;
  partialSignupNavigationHistory: string;
  partialSignupNavigationDepdendents: string;
  success: boolean;
  errors: unknown;
}

@Component({
  selector: 'app-welcome-itm',
  templateUrl: './welcome-itm.component.html',
  styleUrls: ['./welcome-itm.component.scss']
})
export class WelcomeItmComponent implements OnInit, AfterViewInit, OnDestroy {
  oem: string;
  name: string;
  isOemPresentInURL = false;
  pageAfterOemScreen: string;
  screenHeight = 0;
  screenWidth = 0;
  videoLink: SafeResourceUrl;

  constructor(
    private configService: ConfigService,
    private outputService: OutputService,
    private route: ActivatedRoute,
    private location: Location,
    private sanitizer: DomSanitizer,
    private onboardingApiService: OnboardingApiService,
    private loadExternalToolsGuard: LoadExternalToolsGuard,
    private customPreloadingStrategy: CustomPreloadingStrategy
  ) {
    this.onResize();
    this.videoLink = this.sanitizer.bypassSecurityTrustResourceUrl(videoLink);
  }

  ngOnInit(): void {
    // Hide footer, back button
    this.configService.updateHiddenElements({ navigation: true, footer: true });
    this.route.queryParams.subscribe((params) => {
      
      const servicePlanScreen = this.configService.config.screens.find(
        (screenObj) => screenObj.name === 'coverage/service-plan'
      );
      const supportedOems = servicePlanScreen?.oems?.map(
        (oemObj) => oemObj.name
      );
      // If OEM query param present in the URL, skip the OEM screen
      if (params?.oem && supportedOems.includes(params.oem.toLowerCase())) {
        this.getScreenNameAfterOem();
        this.oem = params.oem.toLowerCase();
        this.isOemPresentInURL = true;
      } else if (!!params.key || this.configService.getIsPrepopFlow()) {
        if(!!params.key) {
          this.getPrepopData(params.key);
        } else {
          this.configService.setIsShowPrepopModal(false);
        }
        this.getScreenNameAfterOem();
      } else if (params.saved) {
          this.configService.customerKey = params.saved;
          this.getPartialSignupData(params.saved).catch((err)=>{
            console.warn(err);
          });
      } else {
        this.location.replaceState('/activate/itm/welcome');
      }
    });

  }

  next(): void {
    const outputState = this.outputService.currentOutputState;
    if(this.oem || outputState.oem) {
      outputState.oem = this.oem || outputState.oem;
      outputState.oemId = this.configService.getCarMakeId(outputState.oem);
      this.outputService.updateState(outputState);
    }
    if (this.isOemPresentInURL || this.configService.isOemPresentInURL || this.configService.getIsPrepopFlow()) {
      this.getScreenNameAfterOem();
      this.skipOemScreen(this.pageAfterOemScreen);
    } else {
      this.configService.next();
    }
  }

  async getPartialSignupData(customerKey: string): Promise<void> {
    try {
      const resp: _PartialSignupAPIResponse = await this.onboardingApiService.getDataForPartialSignup(customerKey);
      if (resp.success) {
        this.configService.setPartialSignupData(resp);
        
        for (let screen of this.configService.config.screens) {
          if (screen.name == resp.partialSignupLastPage) {
            this.location.replaceState('/activate/itm/' + resp.partialSignupLastPage);
          }
        }
      }
    } catch (error) {
      console.warn(error);
    }
  }

  ngAfterViewInit(): void {
    // Load external scripts if welcome page is skipped
    try {
      if (!this.configService.externalScripsLoaded) {
        this.loadExternalToolsGuard.canActivate();
      }
    } catch (error) {
      console.warn(error);
    }
    this.customPreloadingStrategy.setPreload(true);
  }

  ngOnDestroy(): void {
    // Show footer, back button for other screens
    this.configService.updateHiddenElements({
      navigation: false,
      footer: false,
    });
  }

  get currentScreen(): Page {
    return this.configService.currentPage;
  }

  skipOemScreen(screen: string): void {
    // Push car make to stepper completed pages for step to display state propely.
    this.configService.completedPages.push("coverage/car-make");
    this.configService.next(screen);
  }

  getScreenNameAfterOem(): void {
    const oemScreenIndex = this.configService.config.screens.findIndex(
      (screenObj) => screenObj.name === 'coverage/car-make'
    );
    this.pageAfterOemScreen = this.configService.config.screens[
      oemScreenIndex + 1
    ].name;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  get bgURL(): string {
    if (this.isScreenSmall)
      return this.currentScreen.backgroundImage?.sm;
    if (this.isScreenMedium)
      return this.currentScreen.backgroundImage?.md;
    return this.currentScreen.backgroundImage?.lg;
  }

  get isScreenSmall(): boolean {
    return this.screenWidth <= devicesBreakpoint.sm;
  }

  get isScreenMedium(): boolean {
    return this.screenWidth <= devicesBreakpoint.md;
  }

  async getPrepopData(customerKey: string): Promise<void> {
    this.configService.clearPrepopDataExceptOem();
    this.configService.setIsPrepopFlow(false);
    try {
      const resp: _PrepopAPIResponse = await this.onboardingApiService.getDataForPrepop(customerKey);
      if (resp.success) {
        this.configService.setPrepopData(resp?.result);
        this.oem = resp?.result?.vehicleMake.toLowerCase();
        this.configService.setSalesForceId(customerKey);
        this.configService.setPrePopId(customerKey);
        this.configService.setIsPrepopFlow(true);
        this.configService.setIsShowPrepopModal(true);
      }
    } catch (error) {
      console.error(error);
    }
  }
}
