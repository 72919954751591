import { Component, OnInit, ViewChild, Renderer2, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ConfigService } from 'src/app/data/services/config.service';
import { OutputService } from 'src/app/data/services/output.service';
import { Subscription, Observable } from 'rxjs';
import { DataService } from 'src/app/data/services/data.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { StepperOrientation } from '@angular/material/stepper';
import { FinishVehiclesComponent } from '../utility/finish-vehicles/finish-vehicles.component'
import {PageGroups} from './PageGroups'
import {getSelectedIndex} from './stepperUtils'
import { map } from 'rxjs/operators';
import { OnboardingApiService } from 'src/app/data/services/onboarding-api.service';
import { DOCUMENT } from '@angular/common';


/**
 * @title Stepper responsive
 */
@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class StepperComponent implements OnInit {
  completed: string[] = [];
  selectedIndex = 0;
  isITMEnabled: boolean;
  isOwnerOp: boolean;
  steps: any;
  stepId: number;
  isCanadianCoverage:boolean = false;
  isPaymentSuccess:boolean = false;
  isStepEditable:boolean = true;
  renderSteps: [{ id: string, title: string, page: string, PageGroup: string[] }];
  stepperOrientation: Observable<StepperOrientation>;
  selectionCalled:boolean = false;
  clickCalled:boolean = false;
  coverageGroup:string[] = PageGroups.ownerop.coverage;

  @ViewChild (FinishVehiclesComponent) FinishVehiclesComponent: FinishVehiclesComponent;
  constructor(
    breakpointObserver: BreakpointObserver,
    private _formBuilder: FormBuilder,
    public configService: ConfigService,
    public outputService: OutputService,
    public data: DataService,
    private onboardingApiService: OnboardingApiService,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private _document
    ){
	    this.stepperOrientation = breakpointObserver.observe('(min-width: 800px)')
      .pipe(map(({matches}) => matches ? 'horizontal' : 'vertical'));
     }

    notifierSubscription: Subscription = this.outputService.SubjectNotifier.subscribe(notified => {
      if(this.configService.lastSelectedFlowPage == 'noVehicleFlow' && this.isOwnerOp){
        this.steps = this.configService.config.stepsFlowB;
      }
      else {
        this.steps = this.configService.config.steps;
      }
      if(this.isCanadianCoverage){
        this.steps = this.configService.config.canadianSteps;
      }
      if(this.isOwnerOp || this.isITMEnabled){
      this.steps.forEach(element => {
        if(element.PageGroup.includes(notified))
        {
          this.stepId = element.id;
        }

      });
        setTimeout(()=>{this.selectedIndex = this.stepId},0)
        const isFlowB = this.configService.lastSelectedFlowPage == 'noVehicleFlow';
        const {previousStepIndex, nextStepIndex} = getSelectedIndex(notified,PageGroups,this.isOwnerOp,this.isITMEnabled,this.isCanadianCoverage,isFlowB);
                setTimeout(()=>{this.selectedIndex = previousStepIndex});
        setTimeout(()=>{this.selectedIndex = nextStepIndex});
        //Store last active step value which can be used in pratial signup process.
        this.configService.stepperSelectedIndex = nextStepIndex;
      }
    })

  ngOnInit() {
    this.isITMEnabled = this.configService.isITMVendor();
    this.isOwnerOp = this.configService.config.vendorId === "owner-op";
    this.selectSteps();
    this.data.currentData.subscribe(value => {
      if(value && this.isITMEnabled){
        this.isCanadianCoverage = value;
        this.configService.isCanadianCoverage = true;
        if(this.isCanadianCoverage) {
          const StepperMenuCanadianRef: HTMLElement = document.getElementById("stepperMenuCanadian");
          if (StepperMenuCanadianRef) {
            StepperMenuCanadianRef.style.height = "145px";
          }
        }
        this.renderSteps = this.configService.config.canadianSteps;
        this.selectedIndex = 0;
      }
      else{
        this.isCanadianCoverage = value;
        this.configService.isCanadianCoverage = false;
        if (!this.isCanadianCoverage) {
          const StepperMenuCanadianRef: HTMLElement = document.getElementById("stepperMenuCanadian");
          if (StepperMenuCanadianRef) {
            StepperMenuCanadianRef.style.height = "300px";
          }
        }
        if(this.configService.lastSelectedFlowPage == 'noVehicleFlow' && this.isOwnerOp){
          this.renderSteps = this.configService.config.stepsFlowB;
        }
        else {
          this.renderSteps = this.configService.config.steps;
        }
        this.selectedIndex = 0;
      }
    });
    this.data.PaymentNotReceivedState.subscribe((value) => {
      if (this.isITMEnabled || this.isOwnerOp) {
        this.isStepEditable = !value;
      }
    })
    this.data.currentSuccessState.subscribe(value=>{
      if (this.isITMEnabled || this.isOwnerOp) {
        if (value) {
          this.isPaymentSuccess = value;

          const stepperElement = document.getElementsByClassName('example-stepper') as HTMLCollectionOf<HTMLElement>;
          if (stepperElement) {
            stepperElement[0].style.pointerEvents = 'none';
            this.selectedIndex = 0;
            setTimeout(() => { this.selectedIndex = 1; });
            setTimeout(() => { this.selectedIndex = 0; });
          }
        }
        else {
          this.isPaymentSuccess = value;
        }
      }
    });

    this.data.stepperState.subscribe(value => {
      if (this.isITMEnabled || this.isOwnerOp) {
        setTimeout(() => { this.selectedIndex = value; });
      }
    });

    this.onboardingApiService.getGooglePlacesScript().then(script => {

      const s = this.renderer2.createElement('script');
      s.type = 'text/javascript';
      s.text = script;

      this.renderer2.appendChild(this._document.head, s);
    }).catch(e => {
      if(e !== 'already loaded') {
        console.error(e);
      }
    });
  }

  selectSteps(){
    if(this.configService.lastSelectedFlowPage == 'noVehicleFlow' && this.isOwnerOp){
      this.renderSteps = this.configService.config.stepsFlowB;
    }
    else {
      this.renderSteps = this.configService.config.steps;
    }
  }

  handleFinishVehicle(){
    this.configService.isCurrentVehicleComplete = true;
    this.configService.isFinishVehiclePopupActive = false;
    this.selectionCalled = true;
    this.pageGroupIncludes(this.configService.currentPage.name,"vehicle/type") && this.configService.next("vehicle/type");
    this.outputService.nextPage("vehicle/type");
  }

  handleContinueStep(){
   this.configService.isCoverageStepCompleted = true;
   this.configService.isFinishVehiclePopupActive = false;
   this.configService.stepperSelectedIndex = 0;
   setTimeout(() => { this.selectedIndex = 1});
   setTimeout(() => { this.selectedIndex = 0});
  }

  handleContinueExtrasStep(){
  const outputState = this.outputService.currentOutputState;  
  this.configService.isExtrasStepCompletd = true;
  this.configService.isFinishVehiclePopupActive = false;
  if(this.configService.lastSelectedFlowPage == 'noVehicleFlow' && this.isOwnerOp){
    this.configService.stepperSelectedIndex = 2;
    setTimeout(() => { this.selectedIndex = 3});
    setTimeout(() => { this.selectedIndex = 2});
  }
  else {
    this.configService.stepperSelectedIndex = 3;
    setTimeout(() => { this.selectedIndex = 4});
    setTimeout(() => { this.selectedIndex = 3});
  }

  if(outputState?.addOnIds[0] === 18){
    this.configService.next("ORDP-multiple-pre-existing-tickets",true,false);
  }
  else{
    this.configService.next("ORDP/pre-existing-tickets",true,false);
  }
  }

  handleRemoveVehicle(){
    const currentFlowKey = this.configService.incompleteVehiclekey;
    const allVehicles = this.outputService.currentOutputState.vehicles;
    //Remove all pages from history of vehicle being deleted
    const updatedHistory = this.configService.history.filter(object => {
      return object.key !== currentFlowKey;
    });
    this.configService.updateHistory(updatedHistory);
    // Filter out incomplete vehicle
    const newVehiclesArray = allVehicles.filter((object)=>{
      return object.key !== currentFlowKey;
    })
    this.outputService.updateState({ vehicles: newVehiclesArray })
    this.configService.currentFlowKey = allVehicles[allVehicles.length-1].key;
    this.configService.isCurrentVehicleComplete = true;
    this.configService.isFinishVehiclePopupActive = false;
    this.configService.next(this.configService.currentStepName === "extras/shippingaddress"?"extras/shippingaddress":"ORDP",true,false);
    this.outputService.nextPage(this.configService.currentStepName === "extras/shippingaddress"?"extras/shippingaddress":"ORDP");
  }

  updateState(step) {
    this.completed.push(step);
  }

  isStepCompleted(input: string, lastpage) {
  let completed = this.configService.completedPages;
  if(completed.includes(lastpage[lastpage.length-1]) ){
    return true;
  }
  else{
    return false;
  }
  }
  selectionChange(event: any) {
    this.selectionCalled = true;
    if(this.isPaymentSuccess) {
      return;
    }
      this.configService.currentStepName = event?.selectedStep?.label;
}

    triggerClick() {
      const currentPageName = this.configService.currentPage.name;
      if(!this.isStepEditable){
        return;
      }
      const firstThreeSteps = ["coverage/new-service-plan", "coverage/trucker-service-plan", "contact/info","vehicle/type"];

      if(this.configService.currentPage.name !== this.configService.currentStepName && this.selectionCalled){
        this.selectionCalled = false;
        // Only switch from trailer to vehicle if user is moving to first 3 steps.
         if(firstThreeSteps.includes(this.configService.currentStepName) && this.isOwnerOp){
          //Remove incomplete vehicle before navigating to earlier steps
          this.filterLastVehicleIndex();
          this.updateVehicleKey();
         }
         if(this.isOwnerOp && this.coverageGroup.includes(currentPageName)){
          this.isCoverageStepCompleted();
         }
         this.configService.isCoverageStepCompleted && this.changeCurrentStep();
      }
  }

  isCoverageStepCompleted(){
    const nextStepName = this.configService.currentStepName;
    try{
      if(this.isOwnerOp && !this.coverageGroup.includes(nextStepName)){
        const outputState = this.outputService.currentOutputState;
        if(outputState?.servicePlan?.title === "Horizon" && !outputState.addByPassval){
          this.configService.isCoverageStepCompleted = false;
        }
       }
    }
    catch(error){
      console.warn(error);
    }
  }

  filterLastVehicleIndex() {
    let AddedVehicles = this.outputService.currentOutputState.vehicles;
    const lastVehicleIndex = AddedVehicles.length - 1;
    if (!Object.keys(AddedVehicles[lastVehicleIndex]).includes("licenseplate") && AddedVehicles.length > 1) {
      const updatedHistoryPages = this.configService.history.filter(pages => {
        return pages.key !== AddedVehicles[lastVehicleIndex].key;
      });
      this.configService.updateHistory(updatedHistoryPages);
      this.outputService.removeRecentVehicle();
    }
  }

  changeCurrentStep() {
    switch(this.configService.currentStepName) {
      case "vehicle/type":
        this.pageGroupIncludes(this.configService.currentPage.name,"vehicle/type") && this.configService.next(this.configService.currentStepName,true,false);
        break;
      case "coverage/trucker-service-plan":
        // For A/B testing retain earlier coverage page.
        this.pageGroupIncludes(this.configService.currentPage.name,"coverage/trucker-service-plan") && this.configService.next( this.configService.lastSelectedCoveragePage || this.configService.currentStepName,true,false);
        break;
      case "contact":
        this.pageGroupIncludes(this.configService.currentPage.name,"contact") && this.configService.next(this.configService.currentStepName,true,false);
        break;
      case "ORDP":
        this.configService.isCurrentVehicleComplete = this.configService.areAllVehicleComplete();
        if(this.configService.lastSelectedFlowPage == 'noVehicleFlow'){
          this.configService.isCurrentVehicleComplete = true;
        }
        this.configService.isCurrentVehicleComplete && this.pageGroupIncludes(this.configService.currentPage.name,"ORDP") && this.configService.next(this.configService.currentStepName,true,false);
        break;
      case "extras/shippingaddress":
        if(this.isOwnerOp){
          this.configService.isCurrentVehicleComplete = this.configService.areAllVehicleComplete();
          if(this.configService.lastSelectedFlowPage == 'noVehicleFlow'){
            this.configService.isCurrentVehicleComplete = true;
          }
        }
        if(this.configService.isCurrentVehicleComplete){
          const isExtasCompleted = this.isExtrasStepCompleted();
          if(this.configService.lastSelectedCoveragePage === "coverage/trucker-service-plan"){
            isExtasCompleted && this.configService.next("extras/shippingaddressspeed",true,false);
          }
          else{
            isExtasCompleted && this.configService.next(this.configService.currentStepName,true,false);
          }
        }
        break;
      default:
        this.configService.next(this.configService.currentStepName,true,false);
    }
  }

  isExtrasStepCompleted(){
  let isExtrasStepCompleted = true;
  const outputState = this.outputService.currentOutputState;
  if(this.isOwnerOp && outputState.addOnIds){
    if(!this.configService.ORDPCompletedPages.includes("ORDP/pre-existing-tickets")){
      isExtrasStepCompleted = false;
      this.configService.isExtrasStepCompletd = false;
    }
    if(outputState.addOnIds[0] === 18 && !this.configService.ORDPCompletedPages.includes("ORDP/multiple-tickets")){
      isExtrasStepCompleted = false;
      this.configService.isExtrasStepCompletd = false;
    }
    if(outputState.ordpticket?.existingORDPticketcount){
      return isExtrasStepCompleted;
    }
  }
  return isExtrasStepCompleted;
  }

  pageGroupIncludes(pageName,stepTitle){
    const vehiclesPages = this.renderSteps.find((elem)=>elem.page === stepTitle);
    return !vehiclesPages.PageGroup.includes(pageName);
  }
  updateVehicleKey(){
  const AllVehicles = this.outputService.currentOutputState.vehicles;
  AllVehicles.length > 0 && AllVehicles.map((elem)=>{
    if(elem.type && this.configService.isVehicleType(elem.type) && elem.key){
     this.configService.currentFlowKey = elem.key;
    }
  })
  }
}
